import type { AppLink, appLogos } from '@htaic/cue'
import { AppLogo } from '@htaic/cue'
import { hanwhaAppsUrls } from '@training/constants'

import { useMinimeState } from '../store/useMinimeState'

const getSubDomain = () => {
  const currentFlexAiUrl = window.location.hostname
  const subDomain = currentFlexAiUrl.includes('relqa') ? 'qa' : currentFlexAiUrl.split('.')[0]

  if (subDomain === 'localhost') {
    return 'dev'
  }
  if (subDomain === 'flexai') {
    return 'prod'
  }
  return subDomain
}

const getUrlBySubDomain = (name: string, subDomain: string, orgId?: string) => {
  const urlObj = hanwhaAppsUrls.find((c) => c.name === name)
  if (urlObj) {
    return orgId
      ? `${urlObj.urls[subDomain as keyof typeof urlObj.urls]}?orgId=${orgId}`
      : urlObj.urls[subDomain as keyof typeof urlObj.urls]
  }
  return null
}

/*
  This hook is used to get the hanwha apps based on the current subdomain.
*/
export const useGetAppLinks = () => {
  const subDomain = getSubDomain()
  const orgId = useMinimeState((state) => state.orgId)
  const appUrls = hanwhaAppsUrls.map((appUrl) => {
    return {
      title: appUrl.name,
      lightLogo: <AppLogo name={appUrl.name as keyof typeof appLogos} theme='light' />,
      darkLogo: <AppLogo name={appUrl.name as keyof typeof appLogos} theme='dark' />,
      href: getUrlBySubDomain(appUrl.name, subDomain!, orgId),
    } as AppLink
  })
  return appUrls
}
