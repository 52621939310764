import type { QueryClient } from '@tanstack/react-query'
import { getLicenseStatus } from '@training/apis/licenses/requests'
import type { LicensesStatusResponse } from '@training/apis/types'
import { envVars, storageKeys, urlParams } from '@training/constants'
import { useMinimeState } from '@training/store/useMinimeState'
import { formatRedirectUrl, getRedirectUrl } from '@training/utils/getRedirectUrl'
import { getUrlParam } from '@training/utils/getUrlParam'
import { storage } from '@training/utils/storage'
import { AxiosError } from 'axios'
import { redirect } from 'react-router-dom'

/**
 * A React Router loader function that checks the license status for the current organization.
 *
 * @param queryClient - The React Query client instance.
 * @returns A promise that resolves to either null (if the license is valid)
 * or a Redirect object (if the user needs to be redirected).
 */
export const licenseLoader = (queryClient: QueryClient) => async () => {
  const orgId = useMinimeState.getState().orgId

  try {
    const licenseStatus =
      queryClient.getQueryData<LicensesStatusResponse>(['licenseStatus', orgId]) ??
      (await queryClient.fetchQuery<LicensesStatusResponse>({
        queryKey: ['licenseStatus', orgId],
        queryFn: async () => getLicenseStatus(orgId),
      }))

    if (licenseStatus !== 'OK') {
      return redirect('/license')
    }
  } catch (error) {
    console.error('License error: ', error)

    const isRedirectToLicense = error instanceof AxiosError && error.response?.status === 401

    if (isRedirectToLicense) {
      return redirect('/license')
    }

    if (envVars.DEV_DISABLE_REDIRECTS) {
      return null
    }

    // requests dont follow 302 redirects, so we need to manually redirect
    return redirect(formatRedirectUrl(getRedirectUrl(), orgId))
  }

  return null
}

const removeParam = (param: string) => {
  if (typeof URLSearchParams !== 'undefined') {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
  }
}

/**
 * A loader that sets the orgId in the store and in local storage
 * @returns the orgId from the url params or the stored orgId
 */

export const organizationLoader = () => {
  const orgIdFromStore = useMinimeState.getState().orgId
  const orgId = orgIdFromStore || getUrlParam(urlParams.ORG_ID) || ''

  storage().setItem(storageKeys.ORG_ID, orgId)

  if (!orgIdFromStore) {
    useMinimeState.setState({ orgId })
  }

  removeParam(urlParams.ORG_ID)

  return orgId
}
