import type { AvailableLicensesResponse } from '@training/apis/types'
import dayjs from 'dayjs'
import { filter, sortBy } from 'lodash'
import { useMemo } from 'react'

export const useGetActiveLicense = (licenses: AvailableLicensesResponse[]) => {
  const [activeLicense] = useMemo(
    () =>
      filter(
        sortBy(licenses, (license) => license.expiryDate),
        (license) =>
          license.licenseStatus === 'ACTIVE' &&
          dayjs().isAfter(license.activeDate) &&
          dayjs().isBefore(license.expiryDate)
      ),
    [licenses]
  )

  const activeLicenseDaysLeft = useMemo(
    () => dayjs(activeLicense?.expiryDate).diff(dayjs().startOf('day'), 'day'),
    [activeLicense]
  )

  return {
    license: activeLicense,
    daysLeft: activeLicenseDaysLeft,
  }
}
