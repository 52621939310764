import {
  Button,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  Typography,
  useToast,
} from '@htaic/cue'
import { useFeatureFlags, useFeatureFlagsState } from '@training/store/useFeatureFlags'
import { map } from 'lodash'

interface PersonalSettingsModalProps {
  onClose: () => void
}

export const FeatureFlagsModal = ({ onClose }: PersonalSettingsModalProps) => {
  const featureFlags = useFeatureFlags()
  const toggleFeatureFlag = useFeatureFlagsState((state) => state.toggleFeatureFlag)

  const { toast } = useToast()

  return (
    <ModalContent>
      <ModalHeader closable>
        <div className='flex flex-col'>
          <Typography variant='heading2'>Feature Flags</Typography>
          <Typography variant='body2' className='text-semantic-secondary'>
            Changes are kept locally and per session
          </Typography>
        </div>
      </ModalHeader>
      <div className='flex flex-col pt-6'>
        <div className='flex'>
          <div className='grid grid-cols-2 gap-x-5 gap-y-2'>
            {map(featureFlags, (flagValue, flagKey: keyof typeof featureFlags) => (
              <div
                className='flex w-full lowercase dark:text-semantic-primary [&>label]:w-full [&>label]:justify-between'
                key={flagKey}
              >
                <Label className='flex items-center gap-2'>
                  {flagKey}
                  <Switch
                    data-testid={`feature-flag-${flagKey}`}
                    onCheckedChange={() => {
                      if (!featureFlags.DEV_FF_MANAGEMENT) {
                        toast({
                          variant: 'error',
                          description: 'Environment not configured to change feature flags',
                          duration: 5000,
                        })
                        return
                      }

                      toggleFeatureFlag(flagKey)
                    }}
                    checked={flagValue}
                  />
                </Label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalFooter className='justify-end pb-8 pt-6'>
        <Button className='ml-2' color='grey' onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
