import type { Modal } from '@htaic/cue'
import { type ReactNode } from 'react'
import { create } from 'zustand'

type ModalSize = React.ComponentProps<typeof Modal>['size']

type ModalState = {
  isOpen: boolean
  content: ReactNode
  size: ModalSize
  openModal: ({
    content,
    size,
    onClose,
  }: {
    content: ReactNode
    size?: ModalSize
    onClose?: () => void
  }) => void
  closeModal: () => void
  onClose?: () => void
  preventClose: boolean
  setPreventClose: (preventClose: boolean) => void
}

export const useModalState = create<ModalState>((set) => ({
  isOpen: false,
  content: '',
  preventClose: false,
  openModal: ({ content, size, onClose }) =>
    set({ isOpen: true, content, size: size || 'md', onClose }),
  size: 'md',
  onClose: undefined,
  setPreventClose: (preventClose) => set({ preventClose }),
  closeModal: () => set({ isOpen: false, onClose: undefined }),
}))
