import type { AvailableLicensesResponse } from '@training/apis/types'

export const validateExpiredLicense = (licenses?: AvailableLicensesResponse[]) => {
  if (!licenses) return false

  const expiredLicense = licenses.find((license) => license.licenseStatus === 'EXPIRED')

  const hasNoActiveLicense = licenses.find((license) => license.licenseStatus === 'ACTIVE')

  return !hasNoActiveLicense && !!expiredLicense
}
