import { SidebarProvider, Toaster, TooltipProvider } from '@htaic/cue'
import { QueryClientProvider } from '@tanstack/react-query'
import { AxiosConfig } from '@training/apis/axios-config'
import { queryClient } from '@training/apis/query-client'
import { ModalProvider } from '@training/providers/ModalProvider'
import { useMinimeState } from '@training/store/useMinimeState'

const AxiosProvider = () => {
  const orgId = useMinimeState((state) => state.orgId)

  return orgId ? <AxiosConfig orgId={orgId} /> : null
}

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider delayDuration={300}>
        <SidebarProvider open={false}>
          <ModalProvider />
          <AxiosProvider />
          <Toaster />
          {children}
        </SidebarProvider>
      </TooltipProvider>
    </QueryClientProvider>
  )
}
