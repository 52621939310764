/* eslint no-alert: 0 */
/**
 * Copyright(c) 2023-2024 by Hanwha Vision, Inc.
 *
 * This software is copyrighted by, and is the sole property of Hanwha Vision.
 * All rights, title, ownership, or other interests in the software remain the
 * property of Hanwha Vision. This software may only be used in accordance with
 * the corresponding license agreement. Any unauthorized use, duplication,
 * transmission, distribution, or disclosure of this software is expressly
 * forbidden.
 *
 * This Copyright notice may not be removed or modified without prior written
 * consent of Hanwha Vision.
 *
 * Hanwha Vision reserves the right to modify this software without notice.
 *
 * Hanwha Vision, Inc.
 * USA
 * https://hanwhavisionamerica.com/
 */

import { invalidate } from '@htaic/anaheim-graphql-library/build/src/index'
import { cloudPlatformLoginUrls, storageKeys } from '@training/constants'
import { useFeatureFlagsState } from '@training/store/useFeatureFlags'

/*
 * NOTE: eslint gets 'confused' and thinks this is a 'hook' and not a pure javascript function
 * when 'use' starts the function name. What fun.
 * Here us the 'official' explanation of this 'feature':
 * https://legacy.reactjs.org/docs/hooks-faq.html#what-exactly-do-the-lint-rules-enforce
 * This hook is used to determine the redirect url for the cloud platform login
 * based on the current flexai url
 * @returns {string} - the redirect url for the cloud platform login
 * by default it will return the first url in the cloudPlatformLoginUrls array
 */
const getRedirectUrl = () => {
  const currentFlexAiUrl = window.location.hostname
  const currentCloudPlatformLoginUrl = cloudPlatformLoginUrls.find(
    (url: { flexaiUrl: string }) => url.flexaiUrl === currentFlexAiUrl
  )
  if (currentCloudPlatformLoginUrl) {
    return currentCloudPlatformLoginUrl.cloudUrl
  }
  return cloudPlatformLoginUrls[0].cloudUrl
}

/**
 * Common function to Logout the user. This should be called from a component that will replace the
 * commented out 'alert' (for testing), first clear the screen and then display a modal popup window that
 * lets the user know that 'Your session has been logged out, click OK to login again.'.
 */
const logoutUser = async (isAddRedirect = true, isCallInvalidate = true) => {
  // Comment this out once there is a 'user friendly' logout popup available.
  // We display the alert before calling invalidate so we can avoid the invalidate call while testing.
  // alert('Your user session has timed out, click OK to login again.') // NOSONAR
  // We call invalidate here in case we get here from the 'logout' button.
  // We ignore any errors returned by invalidate, as some other app has probably already
  // called invalidate to logout the user.
  const cloudPlatformLoginUrl = getRedirectUrl()
  console.log('logoutUser: logging out user (may already be logged out)')
  // TODO: Test that this works fine when called from a logout button.
  if (isCallInvalidate) {
    try {
      await invalidate()
    } catch (err: any) {
      console.log(`logoutUser: ignoring error from invalidate = ${err.message}`)
    }
  }

  const orgId = sessionStorage.getItem(storageKeys.ORG_ID)
  const userId = sessionStorage.getItem(storageKeys.USER_ID)
  // TODO: Pass user_id back as well? Duclo does nothing with it now.
  const port =
    window.location.port !== '80' && window.location.port !== '443'
      ? `:${window.location.port}`
      : ''
  const newHref = isAddRedirect
    ? `${cloudPlatformLoginUrl}?redirect_app_url=${window.location.protocol}//${window.location.hostname}${port}/?orgId=${orgId}&userId=${userId}`
    : `${cloudPlatformLoginUrl}`

  // remove per session values in storage
  useFeatureFlagsState.persist.clearStorage()

  console.log(`redirecting to: ${newHref}`)
  window.location.href = newHref
}

export { logoutUser }
