import { Modal } from '@htaic/cue'
import { useModalState } from '@training/hooks/useModal'
import React, { useCallback } from 'react'

export const ModalProvider = () => {
  const isOpen = useModalState((state) => state.isOpen)
  const onClose = useModalState((state) => state.onClose)
  const size = useModalState((state) => state.size)
  const content = useModalState((state) => state.content)
  const closeModal = useModalState((state) => state.closeModal)
  const preventClose = useModalState((state) => state.preventClose)

  const onDismiss = useCallback(() => {
    if (preventClose) return
    onClose?.()
    closeModal()
  }, [onClose, closeModal, preventClose])

  return (
    <Modal open={isOpen} size={size} onOpenChange={(open) => !open && onDismiss()}>
      <React.Fragment key='modal'>{content}</React.Fragment>
    </Modal>
  )
}
