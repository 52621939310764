import { useCallback, useEffect, useState } from 'react'

import type { Theme } from '../store/useMinimeState'
import { themes } from '../store/useMinimeState'

const useDarkMode = (theme: Theme) => {
  const [darkMode, setDarkMode] = useState(theme === themes.DARK)
  const handleChange = useCallback(
    (e: MediaQueryListEvent) => {
      const newColorScheme = e.matches ? 'dark' : 'light'
      if (theme === themes.SYSTEM) {
        setDarkMode(newColorScheme === themes.DARK)
      }
    },
    [theme, setDarkMode]
  )

  useEffect(() => {
    if (theme === themes.SYSTEM) {
      setDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches)
    } else {
      setDarkMode(theme === themes.DARK)
    }
  }, [theme, setDarkMode])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    mediaQuery.addEventListener('change', handleChange)
    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [handleChange])

  return { darkMode }
}

export default useDarkMode
