import { Typography } from '@htaic/cue'
import type { Theme } from '@training/store/useMinimeState'
import { twMerge } from 'tailwind-merge'

interface AppThemeCardProps {
  selected: boolean
  themeMode: Theme
  themeSrc: string
  themeName: string
  handleClick: () => void
}

export const AppThemeCard = ({
  selected,
  themeMode,
  themeSrc,
  themeName,
  handleClick,
}: AppThemeCardProps) => {
  return (
    <button
      onClick={handleClick}
      aria-label={themeMode}
      type='button'
      className={twMerge(
        'flex flex-col justify-center items-center p-0 border-solid border cursor-pointer border-neutral-grey-14 rounded-lg overflow-hidden bg-transparent',
        'dark:border-neutral-grey-10',
        selected ? 'outline outline-2 outline-offset-[-1px] outline-primary' : null
      )}
    >
      <div
        className={twMerge(
          'flex justify-center items-center w-[168px] h-[92px] bg-neutral-grey-14',
          'dark:bg-neutral-grey-13'
        )}
      >
        <img src={themeSrc} alt='' />
      </div>
      <div
        className={twMerge(
          'flex items-center bg-neutral-grey-16 w-full h-11 pl-4 border-solid border-0 border-t border-neutral-grey-14',
          'dark:bg-neutral-grey-12 dark:text-neutral-grey-0 dark:border-neutral-grey-10'
        )}
      >
        <Typography variant='body2'>{themeName}</Typography>
      </div>
    </button>
  )
}
