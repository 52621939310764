import { IconButton, Typography } from '@htaic/cue'
import { headerBannerTypes } from '@training/constants'
import { useAppState } from '@training/store/useAppState'
import { memo, useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface HeaderBannerProps {
  type: keyof typeof headerBannerTypes
  open: boolean
  onClose?: () => void
  children: string
  className?: string
}

export const HeaderBanner = memo((props: HeaderBannerProps) => {
  const { type, open, onClose, children, className } = props

  const setIsHeaderBannerOpen = useAppState((state) => state.setIsHeaderBannerOpen)

  const [isOpen, setIsOpen] = useState(open)

  const onCloseHandler = useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [onClose])

  useEffect(() => {
    setIsHeaderBannerOpen(isOpen)
  }, [isOpen, setIsHeaderBannerOpen])

  return isOpen ? (
    <div className='z-[41] min-h-[var(--alert-banner-height)]'>
      <div
        className={twMerge(
          'flex justify-center items-center w-full py-2 px-4 h-[var(--alert-banner-height)]',
          headerBannerTypes[type],
          className
        )}
      >
        <Typography variant='body2' className='max-w-[90%] font-medium'>
          {children}
        </Typography>
        <div className='absolute right-5'>
          <IconButton
            data-testid='close-header-banner'
            size='normal'
            icon='Close'
            onClick={onCloseHandler}
          />
        </div>
      </div>
    </div>
  ) : null
})

HeaderBanner.displayName = 'HeaderBanner'
