import { getMiniMe, setAppConfig } from '@htaic/anaheim-graphql-library'
import {
  AppsCard,
  Button,
  Icon,
  Loader,
  Logo,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ProfileCard,
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  Typography,
} from '@htaic/cue'
import { useGetAvailableLicenses } from '@training/apis/licenses/requests'
import { logoutUser } from '@training/auth/logoutUser'
import { HeaderBanner } from '@training/components/HeaderBanner'
import type { HelpLinkProps } from '@training/components/HelpCard'
import HelpCard from '@training/components/HelpCard'
import { FeatureFlagsModal } from '@training/components/Modals/FeatureFlagsModal'
import { PersonalSettingsModalApp } from '@training/components/Modals/PersonalSettingsModal/PersonalSettingsModal'
import {
  cloudPlatformAccountSettingsUrls,
  endpoints,
  envVars,
  flexAiSupportUrl,
  storageKeys,
} from '@training/constants'
import useDarkMode from '@training/hooks/useDarkMode'
import { useGetAppLinks } from '@training/hooks/useGetAppLinks'
import { useModalState } from '@training/hooks/useModal'
import { useAppState } from '@training/store/useAppState'
import { useFeatureFlags } from '@training/store/useFeatureFlags'
import { useLicensePlanState, useLicenseWarningMessage } from '@training/store/useLicenseStatus'
import { themes, useMinimeState } from '@training/store/useMinimeState'
import type { SidebarItem } from '@training/types'
import { getLicensePlanMessage } from '@training/utils/getLicensePlanMessage'
import {
  formatRedirectUrl,
  getEnvironmentName,
  getRedirectUrl,
} from '@training/utils/getRedirectUrl'
import { storage } from '@training/utils/storage'
import { validateExpiredLicense } from '@training/utils/validateExpiredLicense'
import { Suspense, useCallback, useEffect } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Logotype from '../assets/flexAILogotype.svg?react'

const redirectOut = (orgId?: string) => {
  if (envVars.DEV_DISABLE_REDIRECTS) {
    return
  }

  const cloudPlatformLoginUrl = getRedirectUrl()
  window.location.href = formatRedirectUrl(cloudPlatformLoginUrl, orgId)
}

const envName = getEnvironmentName()

const AppLoader = () => (
  <div
    className='flex h-full grow items-center justify-center'
    aria-live='polite'
    aria-label='Loading projects'
    data-testid='loading-projects'
  >
    <Loader
      size='xxxlarge'
      iconName='LoadingThin'
      className='text-primary dark:text-neutral-grey-0'
    />
  </div>
)

const LicenseLimitsWarning = () => {
  const licenseWarningMessage = useLicenseWarningMessage()

  if (licenseWarningMessage.message) {
    return (
      <HeaderBanner open type='warning' className='fixed left-0 top-0'>
        {licenseWarningMessage.message}
      </HeaderBanner>
    )
  }

  return null
}

const LicenseLimitsAlert = () => {
  const { licenseProjectsExceeded, licenseProcessesExceeded, licensesStorageExceeded } =
    useLicensePlanState()

  const availableLicenses = useGetAvailableLicenses()

  // Don't change the order of the keys
  const limitsReachedMessage = getLicensePlanMessage({
    isExpired: validateExpiredLicense(availableLicenses.data),
    processesExceeded: licenseProcessesExceeded,
    projectsExceeded: licenseProjectsExceeded,
    storageExceeded: licensesStorageExceeded,
  })

  if (limitsReachedMessage) {
    return (
      <HeaderBanner open type='error' className='fixed left-0 top-0'>
        {limitsReachedMessage}
      </HeaderBanner>
    )
  }

  return <LicenseLimitsWarning />
}

const menuItems = [
  {
    title: 'Projects',
    url: '/training',
    icon: <Icon name='ThumbnailView' size='normal' className='text-sidebar-icon' />,
    activeIcon: (
      <Icon name='ThumbnailViewFilled' size='normal' className='text-black dark:text-white' />
    ),
  },
  {
    title: 'Devices',
    url: '/training/devices',
    icon: <Icon name='Camera' size='normal' className='text-sidebar-icon' />,
    activeIcon: <Icon name='CameraFilled' size='normal' className='text-black dark:text-white' />,
  },
  {
    title: 'Settings',
    url: '/training/settings',
    icon: <Icon name='Settings' size='normal' className='text-sidebar-icon' />,
    activeIcon: <Icon name='SettingsFilled' size='normal' className='text-black dark:text-white' />,
  },
] satisfies SidebarItem[]

const helpLinks = [
  {
    label: 'Training',
    url: flexAiSupportUrl,
    iconLeft: (
      <Icon name='Trencher' className='mr-2 self-start text-hv-neutral-10 dark:text-hv-neutral-3' />
    ),
    iconRight: (
      <Icon
        name='Next'
        className='-rotate-45 self-center stroke-2 text-hv-neutral-10 dark:text-hv-neutral-3'
      />
    ),
  },
] satisfies HelpLinkProps[]

export const MainLayout = () => {
  const orgId = useMinimeState((state) => state.orgId)

  const setUser = useMinimeState((state) => state.setUser)
  const setTheme = useMinimeState((state) => state.setTheme)
  const setOrgName = useMinimeState((state) => state.setOrgName)

  useEffect(() => {
    if (!orgId) {
      redirectOut()
      return
    }

    async function callMinime() {
      try {
        setAppConfig(endpoints.GRAPHQLURL, undefined, true, orgId)

        const minimeResponse = await getMiniMe()
        if (!minimeResponse) {
          throw new Error('Minime response is empty')
        }

        storage().setItem(storageKeys.USER_ID, minimeResponse.accountId || '')

        sessionStorage.setItem(
          storageKeys.SESSION_TIMEOUT_MINUTES,
          `${minimeResponse.sessionTimeout}`
        )

        setTheme(minimeResponse.userSettings?.theme ?? 'light')
        setUser({
          userName: minimeResponse.username || '',
          firstName: minimeResponse.firstName || '',
          lastName: minimeResponse.lastName || '',
          userId: minimeResponse.accountId || '',
        })
        setOrgName(minimeResponse.orgName ?? '')
      } catch (error) {
        console.error('Error in fetching minime', error)
        redirectOut(orgId)
      }
    }
    void callMinime()
  }, [orgId, setOrgName, setTheme, setUser])

  const appsLinks = useGetAppLinks()
  const { firstName, lastName, userName, theme } = useMinimeState()

  const devFFManagement = useFeatureFlags().DEV_FF_MANAGEMENT

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const onPreferencesClick = useCallback(() => {
    openModal({
      size: 'lg',
      content: <PersonalSettingsModalApp onClose={closeModal} />,
    })
  }, [closeModal, openModal])

  const handleAccountSettingsClick = () => {
    const matchingEntry = cloudPlatformAccountSettingsUrls.find(({ name }) =>
      name.some((env) => env === envName)
    )

    const url = matchingEntry?.url || cloudPlatformAccountSettingsUrls[0].url

    window.location.href = url
  }

  const handleLogOut = useCallback(async () => {
    closeModal()
    await logoutUser(false, true)
  }, [closeModal])

  const onLogoutModalOpen = useCallback(() => {
    openModal({
      size: 'sm',
      content: (
        <ModalContent>
          <ModalHeader closable>Logout</ModalHeader>
          <div className='flex py-4'>
            <Typography variant='body2' className='text-center'>
              Are you sure you want to log out of FLEX AI and the Hanwha Cloud Platform?
            </Typography>
          </div>
          <ModalFooter className='flex justify-end'>
            <Button onClick={handleLogOut}>Logout</Button>
            <Button variant='secondary' onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      ),
    })
  }, [closeModal, handleLogOut, openModal])

  const onFeatureFlagsModalOpen = useCallback(() => {
    openModal({
      size: 'lg',
      content: <FeatureFlagsModal onClose={closeModal} />,
    })
  }, [closeModal, openModal])

  const { darkMode } = useDarkMode(theme)

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', darkMode ? themes.DARK : '')
    if (darkMode) {
      document.body.classList.add(themes.DARK)
    } else {
      document.body.classList.remove(themes.DARK)
    }
  }, [darkMode])

  const isHeaderBannerOpen = useAppState((state) => state.isHeaderBannerOpen)

  return (
    <div
      className='relative flex min-h-svh w-full bg-neutral-grey-16 text-neutral-grey-0'
      data-testid='container-app'
    >
      <div
        className={
          'relative h-svh !w-[calc(var(--sidebar-width-icon)_+_1px)] shrink-0 bg-transparent'
        }
      />
      <Sidebar
        collapsible='none'
        className={twMerge(
          'fixed inset-y-0 z-[41] h-svh !w-[calc(var(--sidebar-width-icon)_+_1px)]',
          isHeaderBannerOpen && 'bottom-0 top-[var(--alert-banner-height)]'
        )}
      >
        <SidebarHeader className='items-center justify-center pt-3'>
          <Logo className='size-7' />
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup className='p-3'>
            <SidebarGroupContent>
              <SidebarMenu>
                {menuItems.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <NavLink to={item.url} className='no-underline' end={item.url === '/training'}>
                      {({ isActive }) => (
                        <SidebarMenuButton tooltip={item.title} size='lg'>
                          {isActive ? item.activeIcon : item.icon}
                          <span>{item.title}</span>
                        </SidebarMenuButton>
                      )}
                    </NavLink>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
      </Sidebar>

      <div className='flex grow flex-col'>
        {!!orgId && <LicenseLimitsAlert />}
        <header
          className={twMerge(
            'flex justify-between items-center text-center pr-6 pl-2 py-2 h-[var(--main-header-height)] bg-sidebar sticky top-0 z-40'
          )}
        >
          <NavLink to='/' className='flex items-center text-left' end>
            <Logotype className='h-[17px] w-[66px] text-hv-neutral-0' data-testid='brand-logo' />
          </NavLink>
          <div className='flex w-1/3 flex-row items-center justify-end'>
            <HelpCard>
              <HelpCard.Trigger className='mr-1' />
              <HelpCard.Popper
                className='rounded-2xl'
                side='bottom'
                align='end'
                helpLinks={helpLinks}
              />
            </HelpCard>
            <AppsCard>
              <AppsCard.Trigger iconSize='xsmall' className='mr-3 hover:bg-hv-neutral-17' />
              <AppsCard.Popper
                className='rounded-2xl'
                side='bottom'
                align='end'
                appsLinks={appsLinks}
              />
            </AppsCard>
            <ProfileCard>
              <ProfileCard.Trigger
                className='[&>span]:bg-hv-primary-7 [&>span]:text-hv-primary-5 [&>span]:dark:bg-hv-primary-8'
                firstName={firstName}
                lastName={lastName}
              />
              <ProfileCard.Content
                side='bottom'
                align='end'
                firstName={firstName}
                lastName={lastName}
                userName={userName}
              >
                <ProfileCard.Action
                  label='Account settings'
                  iconLeft={<Icon name='AdminCircle' size='small' />}
                  iconRight={<Icon name='ChevronRight' size='small' className='p-1' />}
                  onClick={handleAccountSettingsClick}
                />
                <ProfileCard.Action
                  label='Preferences'
                  iconLeft={<Icon name='Settings' size='small' />}
                  iconRight={<Icon name='ChevronRight' size='small' className='p-1' />}
                  onClick={onPreferencesClick}
                />
                {devFFManagement ? (
                  <>
                    <ProfileCard.Separator />
                    <ProfileCard.Action
                      label='Feature Flags (Dev Only)'
                      iconLeft={<Icon name='Protocol' size='small' />}
                      onClick={onFeatureFlagsModalOpen}
                    />
                  </>
                ) : null}

                <ProfileCard.Separator />
                <ProfileCard.Action
                  label='Logout'
                  iconLeft={<Icon name='Exiting' size='small' />}
                  onClick={onLogoutModalOpen}
                />
              </ProfileCard.Content>
            </ProfileCard>
          </div>
        </header>
        <div className='flex grow flex-col bg-sidebar pl-2 pr-6'>
          <Suspense fallback={<AppLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  )
}
