import {
  Button,
  DateField,
  DateFieldCalendar,
  DateInput,
  Label,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Typography,
  useToast,
} from '@htaic/cue'
import {
  useGetAvailableLicenses,
  useGetLicenseStatus,
  useUpdateToClaimLicense,
} from '@training/apis/licenses/requests'
import {
  type AvailableLicensesResponse,
  type ClaimLicense,
  LicenseStatus,
} from '@training/apis/types'
import { useMinimeState } from '@training/store/useMinimeState'
import { getRedirectUrl } from '@training/utils/getRedirectUrl'
import axios from 'axios'
import dayjs from 'dayjs'
import { memo, useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface UnassignedLicensesSelectorProps {
  licenses: AvailableLicensesResponse[]
  onChange: (licenseKey: string) => void
}

const UnassignedLicensesSelector = memo((props: UnassignedLicensesSelectorProps) => {
  const { licenses, onChange } = props

  return (
    <RadioGroup
      onValueChange={(value) => {
        onChange(value)
      }}
      data-testid='license-selector'
      defaultValue={licenses[0]!.licenseKey}
      className='space-y-3 px-3'
    >
      {licenses.map((lic) => (
        <Label
          htmlFor={lic.licenseKey}
          className='flex items-center space-x-2 has-[:enabled]:cursor-pointer'
          key={lic.licenseKey}
        >
          <RadioGroupItem value={lic.licenseKey} id={lic.licenseKey} />
          <span>{lic.licenseKey}</span>
        </Label>
      ))}
    </RadioGroup>
  )
})

UnassignedLicensesSelector.displayName = 'UnassignedLicensesSelector'

const License = () => {
  const orgIdState = useMinimeState((state) => state.orgId)

  const cloudPlatformLoginUrl = getRedirectUrl()
  const { data: licenses = [], isPending, refetch } = useGetAvailableLicenses()

  const { isPending: isPendingClaim, mutateAsync: mutateAsyncClaim } = useUpdateToClaimLicense()
  const { refetch: refetchStatus } = useGetLicenseStatus(orgIdState, { enabled: false })

  const navigate = useNavigate()

  const [startDate, setStartDate] = useState<string | null>(null)
  const [selectedLicense, setSelectedLicense] = useState('')

  const onChange = (newActivateDataValue: string | null, selectedLicenseKey: string) => {
    setStartDate(newActivateDataValue)
    setSelectedLicense(selectedLicenseKey)
  }

  const handleGoToHome = async () => {
    try {
      await refetchStatus()
      navigate('/')
    } catch (error) {
      console.log('Error trying to load licenses.', error)
    }
  }

  const hasActiveLicenses = licenses.some(
    (license) => license.licenseStatus === LicenseStatus.ACTIVE
  )

  const hasPendingActive = licenses.some(
    (license) => license.licenseStatus === LicenseStatus.ASSIGNED_ACTIVATION_PENDING
  )
  const hasUnassignedLicenses = licenses.some(
    (license) => license.licenseStatus === LicenseStatus.UNASSIGNED
  )

  const { toast } = useToast()

  const renderNotFoundValidLicenses = () => {
    return (
      <>
        <Typography variant='body2' className='text-semantic-secondary'>
          Coming soon.
        </Typography>
        <Typography variant='body2' className='text-semantic-secondary'>
          Contact your System Integrator (SI) to get a demo.
        </Typography>
        <Button className='mt-8' data-testid='license-back-button' asChild>
          <Link to={cloudPlatformLoginUrl} className='no-underline'>
            Return to Cloud Portal
          </Link>
        </Button>
      </>
    )
  }

  const createClaimLicense = (licenseKey: string, activeDate: number): ClaimLicense => {
    return {
      licenses: [
        {
          licenseKey,
          activeDate,
        },
      ],
    }
  }

  const activateLicense = useCallback(
    async (claimLicense: ClaimLicense) => {
      try {
        await mutateAsyncClaim(claimLicense)

        toast({
          description: `License "${claimLicense.licenses[0]!.licenseKey}" is now active.`,
          variant: 'success',
        })
        void refetch()
      } catch (error) {
        console.error(error)
        if (axios.isAxiosError<{ message: string; statusCode: number }>(error)) {
          toast({
            variant: 'error',
            description: error.response?.data.message.includes('The activeDate must be')
              ? 'Start date cannot be in the past. Please try again.'
              : 'Failed to activate the license. Please try again later or contact support if the issue persists.',
            duration: 5000,
          })
          return
        }

        toast({
          variant: 'error',
          description:
            'An unexpected error occurred. Please try again or contact support if the issue persists.',
          duration: 5000,
        })
      }
    },
    [mutateAsyncClaim, refetch, toast]
  )

  const handleActivateLicense = () => {
    const parsedStartDate = dayjs(startDate)
    if (!parsedStartDate.isValid()) return

    const unixTimestamp = parsedStartDate.add(dayjs().diff(parsedStartDate)).valueOf()

    const licenseToActivate = selectedLicense || licenses[0]?.licenseKey

    if (!licenseToActivate) return

    const claimLicensePayload = createClaimLicense(licenseToActivate, unixTimestamp)

    void activateLicense(claimLicensePayload)
  }

  const handleSelectLicense = useCallback((value: string) => {
    setSelectedLicense(value)
  }, [])

  const renderSelectLicense = () => {
    const unassignedLicenses = licenses.filter(
      (license) => license.licenseStatus === LicenseStatus.UNASSIGNED
    )
    const licenseKey =
      unassignedLicenses.length === 1 ? unassignedLicenses[0]!.licenseKey : selectedLicense
    return (
      <>
        {unassignedLicenses.length === 1 ? (
          <div className='mb-7 flex flex-col justify-center'>
            <span className='mb-7 flex justify-center'>
              <Typography variant='body2' className='self-center text-semantic-secondary'>
                Please confirm and activate your license key.
              </Typography>
            </span>
            <Typography variant='body1' className='self-center text-semantic-primary'>
              {unassignedLicenses[0]!.licenseKey}
            </Typography>
          </div>
        ) : (
          <div className='mb-8 flex flex-col justify-start'>
            <Typography variant='body2' className='mb-8 text-semantic-secondary'>
              Select the license key you wish to activate.
            </Typography>
            <UnassignedLicensesSelector
              licenses={unassignedLicenses}
              onChange={handleSelectLicense}
            />
          </div>
        )}
        <div className='mb-7 w-72'>
          <Typography variant='body2' className='mb-1 font-semibold text-semantic-secondary'>
            Start date
          </Typography>
          <DateField
            value={startDate ?? ''}
            onChange={(value) => onChange(value, licenseKey)}
            closeOnSelect
          >
            <DateInput data-testid='start-date-input' placeholder='Select activation date' />
            <DateFieldCalendar
              disabled={{
                before: dayjs().startOf('day').toDate(),
              }}
            />
          </DateField>
        </div>
        <Button
          disabled={!dayjs(startDate).isValid()}
          onClick={handleActivateLicense}
          className='w-32 justify-center'
          data-testid='activate-license-button'
        >
          Activate
        </Button>
      </>
    )
  }

  const renderCurrentActiveLicense = () => {
    const activeLicense = licenses.find((license) => license.licenseStatus === LicenseStatus.ACTIVE)
    const formattedDate = dayjs(activeLicense?.expiryDate).format('MMM D, YYYY')
    return (
      <>
        <span className='mb-7'>
          <Typography variant='body2' className='text-semantic-secondary'>
            The following license has been activated and will expire on <b>{formattedDate}</b>.
          </Typography>
        </span>

        <span className='mb-7'>
          <Typography variant='body2' className='mb-7'>
            {activeLicense?.licenseKey}
          </Typography>
        </span>

        <Button onClick={handleGoToHome} data-testid='license-back-button'>
          Enter FLEX AI
        </Button>
      </>
    )
  }

  const renderPendingActiveLicense = () => {
    const pendingActiveLicense = licenses.find(
      (license) => license.licenseStatus === LicenseStatus.ASSIGNED_ACTIVATION_PENDING
    )
    const formattedDate = dayjs(pendingActiveLicense?.activeDate).format('MMM D, YYYY')
    return (
      <>
        <Typography variant='body2' className='text-semantic-secondary'>
          The following license is set to go live on <b>{formattedDate}</b>.
        </Typography>

        <Typography variant='body2' className='mb-6 text-semantic-secondary'>
          Please log back in when the license is active.
        </Typography>

        <Typography variant='body2' className='mb-6'>
          {pendingActiveLicense?.licenseKey}
        </Typography>

        <Button onClick={handleGoToHome} data-testid='license-back-button' asChild>
          <Link to={cloudPlatformLoginUrl}>Back to Cloud Portal</Link>
        </Button>
      </>
    )
  }

  return (
    <div className='mt-[140px] flex flex-col items-center'>
      <span className='mb-2'>
        <Typography tag='h1' variant='heading2'>
          Welcome to FLEX AI
        </Typography>
      </span>

      {isPending || isPendingClaim ? (
        <div
          className='mt-10 flex h-full grow items-center justify-center'
          aria-live='polite'
          aria-label='Loading Licenses'
        >
          <Loader
            size='xxlarge'
            iconName='LoadingThin'
            className='text-primary dark:text-neutral-grey-0'
            label='Loading licenses...'
          />
        </div>
      ) : (
        <>
          {hasActiveLicenses ? renderCurrentActiveLicense() : null}
          {!hasActiveLicenses && hasPendingActive ? renderPendingActiveLicense() : null}
          {!hasActiveLicenses && !hasPendingActive && hasUnassignedLicenses
            ? renderSelectLicense()
            : null}
          {!hasActiveLicenses && !hasPendingActive && !hasUnassignedLicenses
            ? renderNotFoundValidLicenses()
            : null}
        </>
      )}
    </div>
  )
}

export default License
