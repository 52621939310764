import type { AxiosProgressEvent } from 'axios'

export interface VideoUploadPayload {
  organizationId: string
  projectId: string
  createdBy: string
  datasetType: string
  progressCallback?: (progressEvent: AxiosProgressEvent) => void
  file: File
}

export type VideoAnnotationsResponse =
  | {
      [key: string]: Array<{
        location: {
          xmin: number
          ymin: number
          xmax: number
          ymax: number
        }
        confidence: number
        class_id: number
        metadata?: {
          type: string
          classification: 'inference' | 'annotation' | 'incorrect'
          annotation_id: string
        }
      }>
    }
  | {
      [key: string]: {
        bboxes: Array<{
          location: {
            xmin: number
            ymin: number
            xmax: number
            ymax: number
          }
          confidence: number
          class_id: number
          metadata?: {
            type: string
            classification: 'inference' | 'annotation' | 'incorrect'
            annotation_id: string
          }
        }>
        score: number
      }
    }

export type VideoMetadataResponse = {
  id: string
  createdAt: string
  createdBy: string
  deleted: boolean
  datasetType: string
  fileSize: number
  image_files: Array<{
    id: string
    storage_file_name: string
    storage_relative_path: string
    __typename: string
  }>
  mediaType: string
  storageFileName: string
  storageRelativePath: string
  originalFileName: string
  __typename: string
  resolution: {
    width: number
    height: number
  }
  frameRate: number
  videoUrl: string
}

export interface VideoFilePayload {
  id: string
  updatedBy: string
  fileName: string
}

export interface ThumbnailsAndAnnotations {
  videoId: string
  name: string
  annotationMarkerId: string
  images: { image: string; timeOffset: number }[]
  expanded: boolean
  frameRate: number
}

export interface AnnotationMarker {
  imageId: string
  annotationId: string
  videoId: string
  videoTimelineMarker: number
  sourceVideo: string
  objectLocations: ObjectLocation[]
  timelineInSeconds: number | null
  canDeleteLocation: boolean
}

export interface ObjectLocation {
  annotationObjClasses: AnnotationObjClasses
  geometry?: Geometry
  id?: string
  locId?: string
}

export interface AnnotationObjClasses {
  id: string
  name: string
  aka: string
  description: string
}
export interface Geometry {
  type: string
  coordinates: Array<Array<number>>
}

export type CreateAnnotationsPayload = {
  org_id: string
  project_id: string
  user_id: string
  image_width: number
  image_height: number
  image_type: string
  thumbnails: string[]
  timeline_in_seconds: number
  thumbnails_width: number
  thumbnails_height: number
  thumbnails_type: string
  source_video: {
    video_id: string
    timeline_marker: number
  }
  object_locations: Array<{
    obj_class_id: string
    thumbnail: string
    geometry: {
      type: string
      coordinates: Array<Array<number>>
    }
  }>
  annotation_id?: string
  foi_id?: string
}

export interface TrainingAction {
  action: 'start' | 'stop'
}

export interface ValidateAction {
  action: 'start' | 'stop'
  videoFileName: string
}

export type FeatureFlags =
  | 'FF_SHARE_ACROSS_VERSION'
  | 'MULTIPLE_VERIFICATION_LABELS'
  | 'NEW_TRAINING_MESSAGE_EVENT_BRIDGE'
  | 'DELETE_PROJECT'
  | 'CREATE_PROJECT'
  | 'ANNOTATION_AUTOSAVE'
  | 'LOGIN_REDIRECT'
  | 'NEW_ANNOTATE_TAB'
  | 'ALLOW_MULTIPLE_CLIP_UPLOAD'
  | 'ANNOTATION_TIGHTEN'
  | 'THUMBNAILS_SCRUBBING'
  | 'FF_FRAME_OF_INTEREST_ENABLED'
  | 'TRAIN_BUTTON_FOIS_ENABLED'
  | 'FF_ENABLE_TRAINING_ON_FIVE_ANNOTATIONS'
  | 'FF_STREAM_UPLOAD'
  | 'REFERENCE_POINTS'
  | 'DEV_FF_MANAGEMENT'

export interface FeatureFlag {
  id: string
  name: FeatureFlags
  enabled: boolean
  createdAt: string
  updatedAt: string
  organizations: Array<{
    featureId: string
    orgId: string
    enabled: boolean
    __typename: string
  }>
  __typename: string
}

export interface WaveSyncSystem {
  systemId: string
  systemName: string
}

export type WaveSyncLoginResponse = {
  email: string
  systems: Array<WaveSyncSystem>
}

export interface WaveSyncVideo {
  creationTimeMs: number
  creatorUserId: string
  deviceId: string
  durationMs: number
  id: string
  name: string
  serverId: string
  startTimeMs: number
  tags: string[]
  thumbnailUrl: string
}

export interface BookmarkPayload {
  org_id: string
  user_id: string
  project_id: string
  media_bookmark_list: MediaBookmark[]
}

export interface MediaBookmark {
  name: string
  device_id: string
  system_id: string
  pos: number
  duration: number
  quality: 'low' | 'high'
}

export interface WaveSyncUploadVideoResponse {
  status: number
  videoUploadResponse: {
    id: string
    mediaType: string
    fileSize: number
    originalFileName: string
    storageFileName: string
    datasetType: string
    createdAt: string
    createdBy: string
    width: number
    height: number
    frameRate: number
    status: string
  }
}

export type MvpVerificationPayload = {
  keyFrame: number
  coordinates: Array<{
    annotationId?: string
    classification: 'inference' | 'annotation' | 'incorrect'
    location: {
      xmin: number
      ymin: number
      xmax: number
      ymax: number
    }
  }>
}

export type Foi = {
  foiId: string
  frameNumber: number
  isSavedToAnnotations: boolean
  score: number
  bboxes: Array<{
    class_id: number
    location: {
      xmax: number
      xmin: number
      ymax: number
      ymin: number
    }
    confidence: number
  }>
}

export type AnnotationMarkersResponseV2 = {
  foi: Array<Foi>
  annotations: Array<{
    imageId: string
    annotationId: string
    videoId: string
    videoTimelineMarker: number
    sourceVideo: string
    objectLocations: ObjectLocation[]
    canDeleteLocation: boolean
    timelineInSeconds: number | null
  }>
}
export interface BoxifyPayload {
  video_filename: string
  timeline_marker: number
  geometries: Geometry[]
}

export interface BoxifyResponse extends BoxifyPayload {
  organization_id: string
  project_id: string
}

export const LicenseStatus = {
  ACTIVE: 'ACTIVE',
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED_ACTIVATION_PENDING: 'ASSIGNED_ACTIVATION_PENDING',
  EXPIRED: 'EXPIRED',
  STACKED1: 'STACKED1',
  STACKED2: 'STACKED2',
} as const

export type AvailableLicensesResponse = {
  licenseKey: string
  licenseStatus: keyof typeof LicenseStatus
  purchasedOrgId: string
  productName: string
  productDisplayName: string
  trial: boolean
  expiryDate: Date
  activeDate: Date
}

export interface ClaimLicense {
  licenses: {
    licenseKey: string
    activeDate: number
  }[]
}

export type LicenseProps = {
  licenseKey: string
  licenseStatus: keyof typeof LicenseStatus
  purchasedOrgId: string
  productName: string
  productDisplayName: string
  trial: boolean
  expiryDate: Date
  activeDate: Date
}

export type LicensesStatusResponse = 'OK' | 'NOK'

export type BuildInfoResponse = {
  Version: string
  ProductName: string
  DeploymentNumber: string
  LastChanged: string
  Services: Array<{
    ServiceName: string
    BuildNumber: string
    CommitSHA: string
  }>
}

export type GetDevicesResponse = Array<{
  deviceId: string
  deviceName: string
  serialNo: string
  macAddress: string
  modelNumber: string
  connectionStatus: 'offline' | 'online'
  deviceStatus: 'CLAIMED' | 'DEACTIVATED' | 'PENDING_CLAIM'
  vmsStatus?: boolean
  deviceType: string
  orgId: string
  claimed: boolean
  createdDate: number
  imageURL: string
  updatedDate: number
  enteredDate: number
  gatewayId: string
  properties?: {
    'firmware-version': string
    'ip-address': string
    'fw-update'?: string
  }
  channel?: string
  parentId: string
  proto?: string
  dmproPaidLicenseActivated: boolean
  sightmindPaidLicenseActivated: boolean
  location: {
    locationId: string
    locationName: string
  }
  area: {
    areaId: string
    areaName?: string
  }
  capability?: {
    url: string
    version: number
    expiry: number
  }
  dmproStatus?: boolean
  flexAIStatus?: boolean
  sightmindStatus?: boolean
  manufacturer?: string
  activeDate?: number
  registeredDate?: number
  chIndex?: number
}>

export type PortalDevice = {
  deviceId: string
  deviceName: string
  connectionStatus: 'online' | 'offline'
  modelNumber: string
  locationName: string
  areaName: string
  licenseStatus: boolean
  serialNo?: string
  ipAddress?: string
  deployedModels?: {
    objectClassName?: string
    deployedAt?: number
  }[]
  isDeploymentInProgress: boolean
  wiseAIVersion: string
  cloudConnectorVersion: string
}

export type GetPortalDevicesResponse = Array<PortalDevice>

export type LocationItem = {
  locationId: string
  locationName: string
  claimedDevicesCount: number
  unclaimedDevicesCount: number
}

export type PostDeploymentResponse = Array<PostDeploymentInfoItem>

export type PostDeploymentInfoItem = {
  deviceId: string
  projectId: string
  projectVersionId: string
  deploymentId: string
}

export type GetLocationListResponse = Array<LocationItem>

export type GetOnCloudClipsResponse = {
  totalItems: number
  totalPages: number
  currentPage: number
  clips: Array<{
    durationMs: number
    durationInSec: number
    clipId: string
    createdDate: number
    updatedDate: number
    startTime: number
    endTime: number
    clipSize: number
    deviceId: string
    areaId: string
    locationId: string
    tags: Array<{ tagId: string; name: string }>
    status: 'PROCESSED' | (string & {})
    incidentIds: Array<string>
    clip: {
      url: string
      expiry: number
    }
    thumbnail: {
      url: string
      expiry: number
    }
  }>
}

export type PostOnCloudMediaPayload = {
  orgId?: string
  projectId: string
  userId?: string
  url: string
}

export type PostOnCloudResponse = {
  status: number
  videoUploadResponse: {
    id: string
    mediaType: string
    fileSize: number
    originalFileName: string
    storageFileName: string
    datasetType: string
    createdAt: string
    createdBy: string
    width: number
    height: number
    frameRate: number
    status: string
  }
}
export type GetOnCloudTagsResponse = Array<{
  tagId: string
  name: string
  category: string
  isReadOnly: boolean
  count: number
}>

export type PostDeploymentRequest = { deviceId: string; projectId: string }
